<template>
	<div class="partial partial--artist">
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<content-band>
			<div slot="img-top" class="content-band__image content-band__image-short" :style="bioImage"></div>
			<h3 class="content-band__artist-profile">Artist Profile</h3>
			<h2 class="content-band__title">Ryan Coniglario</h2>
			<p>A newcomer to Florida from New York City, Ryan is eager to make a name for himself. As a self-taught artist with five years of tattooing experience, he has even worked alongside an artist from Ink Masters.</p>

			<p>Ryan specializes in traditional tattoo styles, from American to Japanese, and has a particular passion for bright colors. One of his greatest strengths is his ability to learn new techniques and adapt to a wide range of art styles.</p>
			
		</content-band>

		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>

		<image-grid :images="images"></image-grid>
	</div>
</template>

<script>
// Components
import ContentBand from '@/components/ContentBand'
import ImageGrid from '@/components/ImageGrid'
import ButtonGallery from '@/components/ButtonGallery'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artist',
	components: {
		ContentBand,
		ImageGrid,
		ButtonGallery,
		Artists,
	},
	data: () => ({
		bioImage: {
			backgroundImage: 'url(' + require('@/assets/images/artist-ryan.jpg') + ')'
		},
		images: [
			require('@/assets/images/ryan/1.jpg'),
			require('@/assets/images/ryan/2.jpg'),
			require('@/assets/images/ryan/3.jpg'),
			require('@/assets/images/ryan/4.jpg'),
			require('@/assets/images/ryan/5.jpg'),
			require('@/assets/images/ryan/6.jpg'),
			require('@/assets/images/ryan/7.jpg'),
			require('@/assets/images/ryan/8.jpg'),
			require('@/assets/images/ryan/9.jpg'),
			require('@/assets/images/ryan/10.jpg'),
			require('@/assets/images/ryan/11.jpg'),
			require('@/assets/images/ryan/12.jpg'),
			require('@/assets/images/ryan/13.jpg'),
			require('@/assets/images/ryan/14.jpg')
		],
		show: false
	}),
	metaInfo() {
		return {
			title: 'Ryan Coniglario',
			titleTemplate: '%s | Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>

	.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	
</style>
